export default {
    methods: {
        hideError() {
            $('input').removeClass('border-red-500')
            $('select').removeClass('border-red-500')
            $('textarea').removeClass('border-red-500')
            $('small.error_message').hide()
        },
        displayError(error) {
            $.each(error.errors, function (key, value) {
                key = key.replaceAll(".", "_")
                $(`#${key}_message`).html(value[0]).fadeIn()
            })

        }
    }
}