<template>
  <div class="bg-[#F5F7FA]">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>
