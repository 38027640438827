import { defineStore } from 'pinia'

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        authenticated: false,
        access_token: null,
        expires_in:null,
        user:null,
        avatar: null
    }),
    gettters: {
    },
    actions: {
        setAuthenticated(value){
            this.authenticated = value
        },
        setAccessToken(access_token, expires_in){
            this.access_token = access_token
            this.expires_in = expires_in
        },
        setUser(user){
            this.user = user
        },
        setAvatar(avatar){
            this.avatar = avatar
        },
        authLogout(){
            this.authenticated = false
            this.setAccessToken(null, null)
            this.user = null
            this.avatar = null
        }
    },
    persist: {
        enabled: true,
        strategies:[
            {
                key:"auth",
                storage: localStorage
            },
        ]
    }
})