import Echo from 'laravel-echo'
import socketIo from 'socket.io-client' 
import { useAuthStore } from '@/store/auth'

window.io = socketIo

export default {
    install:(app)=>{
        const authStore = useAuthStore()
        const echo = new Echo({
            broadcaster: "socket.io",
            host: process.env.VUE_APP_SOCKET_SERVER,
            auth: {
                headers: {
                    Authorization: `Bearer ${authStore.access_token}`
                }
            },
        })


        /*echo.connector.socket.on('connect', ()=>{
            console.log(echo.socketId())
            window.axios.defaults.headers.common[''] = 
        })*/

        app.config.globalProperties.$echo = echo
    }
}