import { createWebHistory, createRouter } from 'vue-router';
import { useAuthStore } from '@/store/auth';

const routes = [
	// {
	// 	path: '/register',
	// 	name: 'Register',
	// 	component: () => import('@/views/guest/Register.vue'),
	// 	meta: {
    //         middleware: "guest",
    //         title: `Register`
    //     }
	// },
    {
        path:'/:pathMatch(.*)*',
        name: '404',
        component: ()=> import('@/views/PageNotFound.vue'),
        meta:{
            title:"Page Not Found"
        }
    },
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/guest/Login.vue'),
		meta: {
            middleware: "guest",
            title: `Login`
        }
	},
	{
		path: '/',
		name: 'Dashboard',
		component: () => import('@/views/Dashboard.vue'),
		meta: {
            middleware: "auth",
            title: `Dashboard`
        }
	},
    {
        path: '/account',
        name: 'Account',
        component: () => import('@/views/Account.vue'),
        meta: {
            middleware: "auth",
            title: `Account`
        }
    },
    {
        path: '/organization',
        name: 'Organization',
        component: () => import('@/views/Organization.vue'),
        meta: {
            middleware: "auth",
            title: `Organization`
        }
    },
    {
        path: '/server',
        name: 'Server',
        component: () => import('@/views/Server.vue'),
        meta: {
            middleware: "auth",
            title: `Server`
        }
    },
    {
        path: '/application',
        name: 'Application',
        component: () => import('@/views/Application.vue'),
        meta: {
            middleware: "auth",
            title: `Application`
        }
    },
    {
        path: '/database',
        name: 'Database',
        component: () => import('@/views/Database.vue'),
        meta: {
            middleware: "auth",
            title: `Database`
        }
    },
    {
        path: '/queue-size',
        name: 'QueueMonitor',
        component: () => import('@/views/Queue.vue'),
        meta: {
            middleware: "auth",
            title: `Queue Size`
        }
    },
    {
        path: '/cronjob',
        name: 'Cronjob',
        component: () => import('@/views/Cronjob.vue'),
        meta: {
            middleware: "auth",
            title: `Cronjob`
        }
    },
    {
        path: '/transaction',
        name: 'Transaction',
        component: () => import('@/views/Transaction.vue'),
        meta: {
            middleware: "auth",
            title: `Transaction`
        }
    },
    {
        path: '/subscription',
        name: 'Subscription',
        component: () => import('@/views/Subscription.vue'),
        meta: {
            middleware: "auth",
            title: `Subscription`
        }
    },
    {
        path: '/standard-plan',
        name: 'StandardPlan',
        component: () => import('@/views/StandardPlan.vue'),
        meta: {
            middleware: "auth",
            title: `Standard Plan`
        }
    },
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
    const store = useAuthStore()

    document.title = `${to.meta.title}`

    if (to.meta.middleware == "guest") {
        if (store.authenticated) {
            next({ name: "Dashboard" })
        }
        else{
            next()
        }
    }else if(to.meta.middleware == 'auth') {
		if (!store.authenticated) {
            next({ name: "Login" })
        }
        else{
            next()
        }
    }else{
        next()
    }
})

export default router;