import axios from 'axios';
import {useAuthStore} from '@/store/auth'
import Router from '@/router'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    let token = useAuthStore().access_token;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use((response) => response, (error) => {
  if(error.response.status === 401){
    useAuthStore().authLogout()
    Router.push({
      name: 'login'
    })
  }
  throw error;
});

export default instance