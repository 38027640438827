import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
import { defineAsyncComponent } from 'vue'
import App from './App.vue'

import $ from "jquery";
window.$ = window.jQuery = $;

import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

import VueApexCharts from "vue3-apexcharts";

import "./assets/css/style.css"
import router from './router'

const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPersist)
app.use(pinia)

import Echo from '@/plugins/echo'
app.use(Echo)

import axios from '@/plugins/axios'
app.config.globalProperties.$axios = axios

const GuestLayout = defineAsyncComponent(() => import('@/views/layouts/Guest.vue'))
const DefaultLayout = defineAsyncComponent(() => import('@/views/layouts/Default.vue'))
const Skeleton = defineAsyncComponent(() => import('@/components/Skeleton.vue'))
const Table = defineAsyncComponent(() => import('@/components/AppTable.vue'))
const TableSkeleton = defineAsyncComponent(() => import('@/components/TableSkeleton.vue'))

import ErrorMixin from '@/mixins/validationError'

import toast from '@/plugins/toast-notification'
app.config.globalProperties.$toast = toast
app.use(Toast, {
    position: POSITION.TOP_RIGHT
})

app.config.globalProperties.$echo.connector.socket.on('connect', ()=>{
    const socketId = app.config.globalProperties.$echo.socketId()
    axios.defaults.headers.common['X-Socket-ID'] = socketId
})

app.mixin(ErrorMixin)
app.component('GuestLayout', GuestLayout)
app.component('DefaultLayout', DefaultLayout)
app.component('Skeleton', Skeleton)
app.component('Table', Table)
app.component('TableSkeleton', TableSkeleton)

app.use(router)
app.use(VueApexCharts)
app.mount('#app')
